/*
  Get 
   - machine id
   - Password
  to login 

  it use on screen keyboard for inputs 
*/

import React from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import { LoginPageUI } from '../../components/index';
import { alertMail, LOG, MACHINE, MACHINE_TYPE_SETTINGS } from '../../actions/constants';
import { RefreshSendMail, sendMail } from '../../actions/logs';
import { checkIfServiceModeIsOn } from '../../actions/machine';
import { getMachineToken } from '../../actions/serviceMode';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginFailed: false,
      loginFailedReason: '', // TO show message
      allFields: [
        { name: 'MachineId', type: 'text', value: '' },
        { name: 'Password', type: 'password', value: '' },
      ],
      activeFieldIndex: 0, // To set input position 0 for MachineId and 1 for Password
      submitting: false,
      manualReloadTry: 0,
      isError: false,
      errorMsg: '',
    };
  }

  async componentDidMount() {
    const { token, machineId, history } = { ...this.props };
    RefreshSendMail();
    const requestBody = {
      to: alertMail,
      subject: `${localStorage.getItem('machineId')} - User on login page`,
      html_body: `User on login page`,
      alt_body: 'Tested',
    };

    sendMail(requestBody);
    if (token && machineId) {
      return history.push('/');
    }
    const data = await getMachineToken();
    if (!data.error) {
      localStorage.setItem('token', data.token);
      localStorage.setItem('machineId', data.machineId);
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
    console.log('no token in common-env');
    return {};
  }

  onKeyboardInput = button => {
    const { addClickToLog } = { ...this.props };
    addClickToLog('keyBoardInput');
    const { activeFieldIndex, allFields } = { ...this.state };
    if (button === '&#8592;') {
      allFields[activeFieldIndex].value = allFields[activeFieldIndex].value.substring(
        0,
        allFields[activeFieldIndex].value.length - 1,
      );
    } else if (button === '{enter}') {
      return;
    } else if (button === '{tab}') {
      if (activeFieldIndex + 1 > allFields.length) {
        this.closeKeyboard();
      } else {
        this.setState({ activeFieldIndex: activeFieldIndex + 1 });
      }
    } else if (button === '{space}') {
      allFields[activeFieldIndex].value += ' ';
    } else {
      allFields[activeFieldIndex].value += button;
    }

    this.setState({ allFields });
  };

  onInput = e => {
    if (e.key === 'Enter') {
      return;
    }
    if (e.keyCode === 8 || e.keyCode === 46) {
      this.onKeyboardInput('&#8592;');
    } else {
      const inp = e.key;
      if (/[-a-zA-Z0-9-_ @!#$%^&*()<>.?|\\/'":;~`+=}{[\]]/.test(inp) && inp.length === 1) {
        // Accept given inputs and 1 char at a time
        this.onKeyboardInput(e.key);
      }
    }
  };

  cancel = () => {
    this.setState({
      loginFailed: false,
      allFields: [
        { name: 'MachineId', type: 'text', value: '' },
        { name: 'Password', type: 'password', value: '' },
      ],
      activeFieldIndex: 0,
      isCapsOn: false,
      isShiftOn: false,
    });
  };

  openKeyboard = fieldIndex => {
    this.setState({ activeFieldIndex: fieldIndex });
  };

  openServiceMode = () => {
    window.location.href = process.env.REACT_APP_SERVICE_MODE_URL;
  };

  handleSubmit = async () => {
    const { addClickToLog, setLoggedIn, history, setMachineTypeDidNotMatch, setMachineTypeDidMatch } = this.props;
    const { allFields } = { ...this.state };
    addClickToLog('handleSubmit-login');
    const machineId = allFields[0].value;
    const password = allFields[1].value;
    this.setState({
      submitting: true,
    });
    let error;
    let message;
    if (machineId.length < 4) {
      error = true;
      message = 'Please enter a valid machine ID (Minimum 4 digits)';
    } else if (password.length < 5) {
      error = true;
      message = 'Please enter a valid password (Minimum 5 digits)';
    } else {
      const response = await login(machineId, password);
      let { type } = response;
      const { token } = response;
      ({ error, message } = response);
      if (!error) {
        this.setState({
          submitting: false,
          loginFailed: false,
        });
        await window.localStorage.setItem('token', token);
        if (
          ![
            'big',
            'small',
            'imshow',
            'rowproductselect',
            'selectionbyproductid',
            'newuiwithoutphoto',
            'newuiwithoutphotowithoutscroll',
            'newuiwithphoto',
          ].includes(type.toLowerCase())
        ) {
          setMachineTypeDidNotMatch();
          type = 'big';
        } else {
          setMachineTypeDidMatch();
        }
        const machineSettings = MACHINE_TYPE_SETTINGS[type.toLowerCase()];
        // if (!machineSettings) {
        // console.log('Not able to get MACHINE_TYPE_SETTINGS for', type.toLowerCase());
        // }
        machineSettings.machineId = machineId;
        await setLoggedIn({
          token,
          machineSettings,
        });

        return history.push('/');
      }
    }
    return this.setState({
      submitting: false,
      loginFailed: true,
      loginFailedReason: message,
    });
  };

  manualReload = () => {
    const { manualReloadTry } = { ...this.state };
    if (manualReloadTry >= 1) {
      return window.location.reload();
    }
    return this.setState({
      manualReloadTry: manualReloadTry + 1,
    });
  };

  openServiceMode = async () => {
    try {
      const { error } = await checkIfServiceModeIsOn();
      if (!error) {
        window.location.href = process.env.REACT_APP_SERVICE_MODE_URL;

        this.setState({
          isError: false,
          errorMsg: '',
        });
      } else {
        this.setState({
          isError: true,
          errorMsg: 'Service mode app is not runnning',
        });
        // console.log('ERROR IN OPENING SERVICE MODE');
      }
    } catch (e) {
      this.setState({
        isError: true,
        errorMsg: 'Service mode app is not runnning',
      });
      // console.log('EXCEPTION IN OPENING SERVICE MODE');
    }
  };

  componentDidCatch() {
    this.setState({
      errorInComponent: true,
    });
  }

  render() {
    const { loginFailed, isError, errorMsg, submitting, allFields, activeFieldIndex, loginFailedReason } = {
      ...this.state,
    };

    return (
      <div>
        <LoginPageUI
          loginFailed={loginFailed}
          openServiceMode={this.openServiceMode}
          loginFailedReason={loginFailedReason}
          allFields={allFields}
          submitting={submitting}
          isError={isError}
          errorMsg={errorMsg}
          handleSubmit={this.handleSubmit}
          openKeyboard={data => this.openKeyboard(data)}
          onInput={this.onInput}
          activeFieldIndex={activeFieldIndex}
          onKeyboardInput={this.onKeyboardInput}
          manualReload={this.manualReload}
        />
      </div>
    );
  }
}

const mapState = state => ({
  token: state.machine.token,
  machineId: state.machine.machineId,
  loggedIn: state.machine.loggedIn,
});

const mapDispatch = dispatch => ({
  setLoggedIn: payload => dispatch({ type: MACHINE.SET_LOGGED_IN, payload }),
  setMachineTypeDidNotMatch: () => dispatch({ type: MACHINE.MACHINE_TYPE_DID_NOT_MATCH }),
  setMachineTypeDidMatch: () => dispatch({ type: MACHINE.MACHINE_TYPE_DID_MATCH }),

  addClickToLog: payload => dispatch({ type: LOG.ADD_CLICK_TO_LOG, payload }),
});

export default connect(mapState, mapDispatch)(Login);
