import axios from 'axios/index';
import {
  CASH_MODULE_STATUS,
  CASH_REDUCER,
  MQTT_STATUS,
  VEND_REDUCER,
  PAYMENT_STATUS,
  REQUEST_REDUCER_TYPES,
  VEND_STATUS,
  ESP_STATUS,
  MACHINE,
  TIMER,
  MODAL_REDUCER,
  ERROR_CODE,
  RFID_MODULE_STATUS,
  RFID_MODULE,
  CASH_RETURNER_STAGE,
  CURRENCY_RETURNER_STAGE,
  alertMail,
} from './constants';
import { cashModuleStatusUpdate, cashReceivedUpdate, currencyModuleResponse, disablingCashModule } from './cashActions';
import { updateMachineStatus } from './machine';
import heartbeatResponseSchema, {
  cashReceivedValidate,
  sendResForResponseValidate,
  cashInitResponseValidate,
  paymentCompleteMessageValidate,
  cashDispensedMessageValidate,
  coinReturnerInitReponseValidate,
  currencyReturnerDispensingValidate,
  currencyReturnerDispensedValidate,
} from '../apiValidation/mqtt';
import { sendMail } from './logs';
import getChannels from './mqttChannels';

let mqttChannels = {};
const { Paho } = window;

const cashModuleInitiated = data => dispatch => {
  cashModuleStatusUpdate(data.status, data.requestId, data.message, data.code).then(responseData => {
    if (!responseData.error) {
      if (data.isReady && data.status !== 'BROKENDOWN') {
        return dispatch({
          type: CASH_REDUCER.UPDATE_CASH_MODULE,
          payload: {
            status: CASH_MODULE_STATUS.ACCEPTING,
          },
        });
      }

      return dispatch({
        type: CASH_REDUCER.UPDATE_CASH_MODULE,
        payload: {
          status: CASH_MODULE_STATUS.BROKEN,
        },
      });
    }

    return dispatch({
      type: CASH_REDUCER.UPDATE_CASH_MODULE,
      payload: {
        status: CASH_MODULE_STATUS.BROKEN,
      },
    });
  });
};

const currencyModuleResponseApi = data => async dispatch => {
  const { totalCoinPayout, totalCashPayout, message, error, machineId, requestId } = data;
  await currencyModuleResponse({ machineId, requestId, totalCoinPayout, totalCashPayout, message, error }).then(
    responseData => {
      // console.log(responseData, 'currencyModuleResponseApi');
      dispatch({
        type: CURRENCY_RETURNER_STAGE.DISPENSED,
        payload: Object.assign({}, responseData),
      });
    },
  );
};

const cashReceived = data => dispatch => {
  // billCollected = parseFloat(billCollected.trim(), 10);
  //
  cashReceivedUpdate({
    amount_added: data.billCollected,
    requestId: data.requestId,
    note_uuid: data.uuid,
    comment: 'no comments',
    status: 'success',
    type: data.paymentType ? data.paymentType : null,
  })
    .then(responseData => {
      if (responseData) {
        if (!responseData.error) {
          dispatch({
            type: REQUEST_REDUCER_TYPES.UPDATE_TOTAL_AMOUNT,
            payload: { status: PAYMENT_STATUS.PARTIAL, addedAmount: data.billCollected },
          });
        }
        if (responseData.is_txn_completed) {
          dispatch({
            type: REQUEST_REDUCER_TYPES.FINAL_PAYMENT,
            payload: {
              status: PAYMENT_STATUS.COMPLETED,
              totalAmountPaid: data.totalCashCollected,
              method: 'cash',
            },
          });
          dispatch({
            type: MODAL_REDUCER.OPEN_VEND_MODAL,
            payload: true,
          });
        }
      } else {
        return dispatch({
          type: REQUEST_REDUCER_TYPES.UPDATE_TOTAL_AMOUNT,
          payload: {
            status: PAYMENT_STATUS.PARTIAL,
            addedAmount: 0,
          },
        });
      }

      return {};
    })
    .catch(e => {
      if (e) {
        return dispatch({
          type: REQUEST_REDUCER_TYPES.UPDATE_TOTAL_AMOUNT,
          payload: {
            status: PAYMENT_STATUS.PARTIAL,
            addedAmount: 0,
          },
        });
      }
      return {};
    });
};

const disableCashModuleNoPayoutResponse = async (requestIdSaved, message) => {
  // Disable Cash Module

  const totalCoinPayout = [];
  const totalCashPayout = [];
  const error = true;
  const machineId = localStorage.getItem('machineId');

  window.heap.track('disable cash machine', {
    time: Date.now(),
  });
  const requestBody = {
    requestId: requestIdSaved,
    comment: 'Disable Cash Module',
  };
  window.customClearSetTimeout(window.initiatingCachModuleTimout, TIMER.INITIATING_CASH_MACHINE_30_SEC);

  await disablingCashModule(requestBody);
  const data = await currencyModuleResponse({
    machineId,
    requestId: requestIdSaved,
    totalCoinPayout,
    totalCashPayout,
    message,
    error,
  });
  return data;
};

function vendingStarted() {
  return {
    type: VEND_REDUCER.START, // Vending Started by MQTT
  };
}

export const machineBusy = data => {
  const status = data.m; // Status of machine
  return {
    type: VEND_REDUCER.STATUS_UPDATE,
    payload: {
      vendStatus: status,
    },
  };
};

export const vendingFailed = () => {
  return {
    type: VEND_REDUCER.STATUS_BROKEN,
  };
};

export const itemVendingComplete = data => {
  return {
    type: VEND_REDUCER.UPDATE,
    payload: {
      isSuccess: data.s,
      itemId: data.oiid,
      pos: data.items,
    },
  };
};

export const orderVendingComplete = () => {
  return {
    type: VEND_REDUCER.STATUS_UPDATE,
    payload: {
      status: VEND_STATUS.VEND_COMPLETE,
    },
  };
};

export const refreshMachine = () => ({
  type: MACHINE.REFRESH_MACHINE,
});

export const updateEspStatus = () => ({
  type: MACHINE.SET_ESP_STATUS,
  payload: {
    status: ESP_STATUS.CONNECTED,
  },
});

export const checkMultivac = async () => {
  // After successful payment, vend
  try {
    const url = `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/api/checkVMC`;

    const response = await axios({
      url,
      method: 'GET',
      timeout: 1000 * 20,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const connectToMQTT = multivacDetails => async (dispatch, store) => {
  // console.log('connecting to mqtt: ', multivacDetails);
  // console.log('store', store());
  mqttChannels = getChannels();
  // console.log('mqttChannels', mqttChannels);
  if (store().machine.mqttStatus === MQTT_STATUS.CONNECTED) {
    return;
  }
  const { clientId, host, port, password, username, useSSL } = { ...multivacDetails };

  // console.log('clientId ', clientId);
  window.client = new Paho.MQTT.Client(host, port, clientId);
  const { client } = { ...window };
  dispatch({
    type: MACHINE.SET_MQTT_STATUS,
    payload: {
      status: MQTT_STATUS.CONNECTING,
    },
  });
  const options = {
    useSSL,
    userName: username,
    password,
    onSuccess: () => {
      // console.log('mqtt connection success');

      client.subscribe(mqttChannels.machineBusy);
      client.subscribe(mqttChannels.kioskRefresh);
      client.subscribe(mqttChannels.initRespone);
      client.subscribe(mqttChannels.heartBeatResponse);
      client.subscribe(mqttChannels.orderItemResponse);
      client.subscribe(mqttChannels.orderStatus);
      client.subscribe(mqttChannels.cashInitResponse);
      client.subscribe(mqttChannels.rfidEnableResponse);
      client.subscribe(mqttChannels.rfidCardDetails);
      client.subscribe(mqttChannels.paymentComplete);
      client.subscribe(mqttChannels.orderPicked);
      client.subscribe(mqttChannels.coinReturnerDispensing);
      client.subscribe(mqttChannels.coinReturnerDispensed);
      client.subscribe(mqttChannels.currencyReturnerDispensing);
      client.subscribe(mqttChannels.currencyReturnerDispensed);

      // console.log('RFID_ENABLED', mqttChannels.rfidEnableResponse);

      dispatch({
        type: MACHINE.SET_MQTT_STATUS,
        payload: {
          status: MQTT_STATUS.CONNECTED,
        },
      });
      checkMultivac(); // Ping ESP
    },
    onFailure: () => {
      connectToMQTT(multivacDetails);
      // client = new Paho.MQTT.Client(locationHostname, locationPort, clientId);
      dispatch({
        type: MACHINE.SET_MQTT_STATUS,
        payload: {
          status: MQTT_STATUS.DISCONNECTED,
        },
      });
    },
  };

  client.onConnectionLost = () => {
    // client = new Paho.MQTT.Client(locationHostname, locationPort, clientId);
    // console.log('mqtt connection lost', error);
    connectToMQTT(multivacDetails);
    dispatch({
      type: MACHINE.SET_MQTT_STATUS,
      payload: {
        status: MQTT_STATUS.DISCONNECTED,
      },
    });
  };
  client.onMessageArrived = async message => {
    const destination = message.destinationName;

    // console.log('mqtt msg');
    // console.log(destination);
    // console.log(message.destinationName);

    try {
      const receivedData = JSON.parse(message.payloadString);
      // console.log(receivedData);

      if (destination === mqttChannels.machineBusy) {
        dispatch(machineBusy(receivedData));
      } else if (destination === mqttChannels.initRespone) {
        if (receivedData.m !== 'SUCCESS' || receivedData.e === true) {
          // console.log('Error in vmc initRespone');
          // Dispatch after few seconds as mqtt publish is faster than redux state update.
          window.setTimeout(() => dispatch(vendingFailed(receivedData)), 1000 * 2);
        } else {
          // console.log('Success in vmc initRespone');
          dispatch(vendingStarted(receivedData));
        }
      } else if (destination === mqttChannels.heartBeatResponse && heartbeatResponseSchema(receivedData)) {
        // console.log("Received heartbeat response!");
        try {
          if (
            !store().requestReducer.requestId ||
            store().requestReducer.requestId === null ||
            Number.isNaN(store().requestReducer.requestId)
          ) {
            if (receivedData.m === 'BREAKDOWN') {
              updateMachineStatus({
                statusCode: ERROR_CODE.VMC_BROKEN,
                message: 'VMC not connected because of sensor error. But we are still getting orders',
                mode: 'periodic',
                token: localStorage.getItem('token'),
              });
              dispatch(updateEspStatus());
            } else {
              dispatch(updateEspStatus());
            }
          } else {
            // console.log('user is request id is', +store().requestReducer.requestId);
            // console.log('got heartbeat response and doing nothing for it as user is ordering items');
          }
        } catch (e) {
          // console.log(e);
        }
      } else if (destination === mqttChannels.kioskRefresh) {
        dispatch(refreshMachine());
      } else if (destination === mqttChannels.orderItemResponse && sendResForResponseValidate(receivedData)) {
        // we have to unsubscribe it as its opening vend modal every time if send some thing on /CASH_COLLECTION_COMPLETED

        dispatch(itemVendingComplete(receivedData));
      } else if (destination === mqttChannels.orderStatus) {
        dispatch(orderVendingComplete(receivedData));
      } else if (destination === mqttChannels.cashInitResponse && cashInitResponseValidate(receivedData)) {
        // isReady
        const { isReady } = { ...receivedData };
        if (isReady) {
          if (store().requestReducer.requestId === receivedData.requestId) {
            // change
            window.customClearSetTimeout(window.initiatingCachModuleTimout, TIMER.INITIATING_CASH_MACHINE_30_SEC);
            client.subscribe(mqttChannels.cashReceived);
            dispatch(cashModuleInitiated(receivedData));
          } else {
            const requestBody = {
              to: alertMail,
              subject: `${localStorage.getItem('machineId')} - cashInitResponseValidate - request id did not matched`,
              html_body: `Machine have  ${store().requestReducer.requestId} and cash machine gave ${
                receivedData.requestId
              } rqid `,
              alt_body: 'Tested',
            };
            sendMail(requestBody);
          }
        } else if (!isReady) {
          // dispatch({ type: CASH_REDUCER.UPDATE_CASH_MODULE, payload: { status: CASH_MODULE_STATUS.BROKEN } });
          // window.customClearSetTimeout(window.initiatingCachModuleTimout, TIMER.INITIATING_CASH_MACHINE_30_SEC);
        }
      } else if (destination === mqttChannels.cashReceived && cashReceivedValidate(receivedData)) {
        if (store().requestReducer.requestId === receivedData.requestId) {
          dispatch(cashReceived(receivedData));
        } else {
          const requestBody = {
            to: alertMail,
            subject: `${localStorage.getItem('machineId')} - cashReceived - request id did not matched`,
            html_body: `Machine have  ${store().requestReducer.requestId} and cash machine gave ${
              receivedData.requestId
            } rqid `,
            alt_body: 'Tested',
          };
          sendMail(requestBody);
        }
      } else if (destination === mqttChannels.paymentComplete && paymentCompleteMessageValidate(receivedData)) {
        if (store().requestReducer.requestId === receivedData.requestId) {
          if (receivedData.status === 'success') {
            dispatch({
              type: REQUEST_REDUCER_TYPES.FINAL_PAYMENT,
              payload: Object.assign({}, receivedData, {
                status: PAYMENT_STATUS.COMPLETED,
              }),
            });
          }
        } else {
          const requestBody = {
            to: alertMail,
            subject: `${localStorage.getItem(
              'machineId',
            )} - paymentCompleteMessageValidate - request id did not matched`,
            html_body: `Machine have  ${store().requestReducer.requestId} and cash machine gave ${
              receivedData.requestId
            } rqid `,
            alt_body: 'Tested',
          };
          sendMail(requestBody);
        }
      } else if (destination === mqttChannels.orderPicked) {
        // console.log('Trigering dispatch VEND_REDUCER.ORDER_PICKED');
        dispatch({
          type: VEND_REDUCER.ORDER_PICKED,
        });
      } else if (destination === mqttChannels.rfidEnableResponse) {
        // console.log('rfidEnableResponse', receivedData);
        // if (store().requestReducer.requestId === receivedData.requestId) {
        // if (store().rfidModule.rfidModuleStatus === RFID_MODULE_STATUS.INITIATING) {
        if (receivedData.status === true) {
          dispatch({
            type: RFID_MODULE.UPDATE_RFID_MODULE,
            payload: RFID_MODULE_STATUS.ACCEPTING,
          });
        } else {
          dispatch({
            type: RFID_MODULE.UPDATE_RFID_MODULE,
            payload: RFID_MODULE_STATUS.BROKEN,
          });
        }
        // } else {
        //   // console.log('GOT RFID INIT WHILE RFID Stage IS', store().rfidModule.rfidModuleStatus);
        // }

        // }
      } else if (destination === mqttChannels.rfidCardDetails) {
        // if (store().requestReducer.requestId === receivedData.requestId) {
        // Will get tag id
        // console.log('rfidCardDetails', receivedData, store().requestReducer.requestId);
        if (!receivedData.requestId) {
          receivedData.requestId = store().requestReducer.requestId;
          // console.log('Do not got requestId rfidCardDetails so adding the saved one', receivedData);
        }
        dispatch({
          type: RFID_MODULE.SET_CARD_DETAILS,
          payload: receivedData,
        });
      } else if (destination === mqttChannels.coinReturnerDispensing && coinReturnerInitReponseValidate(receivedData)) {
        // console.log('SENDING');

        /* eslint-disable camelcase */
        if (store().requestReducer.requestId === receivedData.request_id) {
          dispatch({
            type: CASH_RETURNER_STAGE.SET_STATUS,
            payload: CASH_RETURNER_STAGE.DISPENSING,
          });
        } else {
          const requestBody = {
            to: alertMail,
            subject: `${localStorage.getItem('machineId')} - coinReturnerDispensing - request id did not matched`,
            html_body: `Machine have  ${store().requestReducer.requestId} and coin machine gave ${
              receivedData.requestId
            } rqid `,
            alt_body: 'Tested',
          };
          sendMail(requestBody);
        }
      } else if (destination === mqttChannels.coinReturnerDispensed && cashDispensedMessageValidate(receivedData)) {
        dispatch({
          type: CASH_RETURNER_STAGE.DISPENSED,
          payload: receivedData.data,
        });
      } else if (
        destination === mqttChannels.currencyReturnerDispensing &&
        currencyReturnerDispensingValidate(receivedData)
      ) {
        if (store().requestReducer.requestId === receivedData.requestId) {
          if (receivedData.isReady === true) {
            dispatch({ type: CURRENCY_RETURNER_STAGE.SET_STATUS, payload: CURRENCY_RETURNER_STAGE.DISPENSING });
            window.setTimeout(async () => {
              if (store().currencyReducer.currencyReturnerStage === CURRENCY_RETURNER_STAGE.DISPENSING) {
                const { requestId } = store().requestReducer;
                await disableCashModuleNoPayoutResponse(requestId, 'Currency returner not responding').then(
                  responseData => {
                    // console.log('Currency returner not responding');
                    dispatch({
                      type: CURRENCY_RETURNER_STAGE.DISPENSED,
                      payload: Object.assign({}, responseData),
                    });
                  },
                );
              }
            }, 1000 * 30); // get from db here
          } else {
            const { requestId } = store().requestReducer;
            await disableCashModuleNoPayoutResponse(
              requestId,
              'Currency returner not ready since isReady false from payment service',
            ).then(responseData => {
              // console.log('Currency returner not ready since isReady false from payment service');
              dispatch({
                type: CURRENCY_RETURNER_STAGE.DISPENSED,
                payload: Object.assign({}, responseData),
              });
            });
          }
        } else {
          const requestBody = {
            to: alertMail,
            subject: `${localStorage.getItem('machineId')} - Got invalid data on currencyReturnerDispensing topic`,
            html_body: `Machine have  ${store().requestReducer.requestId} and coin machine gave ${
              receivedData.requestId
            } rqid 
            mqtt Data ${JSON.stringify(receivedData)}`,
            alt_body: 'Tested',
          };
          sendMail(requestBody);
        }
      } else if (
        destination === mqttChannels.currencyReturnerDispensed &&
        currencyReturnerDispensedValidate(receivedData)
      ) {
        // currencyModuleResponse
        if (store().requestReducer.requestId === receivedData.requestId) {
          dispatch(currencyModuleResponseApi(receivedData));
        } else {
          const requestBody = {
            to: alertMail,
            subject: `${localStorage.getItem('machineId')} - Got invalid data on currencyReturnerDispensed topic`,
            html_body: `Machine have  ${store().requestReducer.requestId} and coin machine gave ${
              receivedData.requestId
            } rqid 
            mqtt Data ${JSON.stringify(receivedData)}`,
            alt_body: 'Tested',
          };
          sendMail(requestBody);
        }
      } else {
        const requestBody = {
          to: alertMail,
          subject: `${localStorage.getItem('machineId')} - Got wrong mqtt data`,
          html_body: `MId got ${JSON.stringify(receivedData)} on ${destination} topic. App version ${
            process.env.REACT_APP_VERSION
          }`,
          alt_body: 'Tested',
        };
        sendMail(requestBody);
      }
      // dispatch({ type: 'MQTT_TEMP_SUCCESS', payload: receivedData });
    } catch (e) {
      updateMachineStatus({
        statusCode: ERROR_CODE.MQTT_MESSAGE_PARSE_ERROR,
        message: `Mqtt message not get parsed, ${message}`,
        mode: 'interaction',
        token: localStorage.getItem('token'),
      });
    }
  };
  await client.connect(options);
};

export const vendNow = async ({ requestId }) => {
  // After successful payment, vend
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/vendNow`;

    const requestBody = {
      order_id: requestId,
    };

    const response = await axios({
      url,
      method: 'post',
      data: requestBody,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};
