import React from 'react';

// export default () => (
export default class Footer extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { checkRefillMode } = { ...this.props };
    return (
      <div className="footer" onClick={() => checkRefillMode()} onKeyDown={this.handleClick} role="button" tabIndex={0}>
        <span className="footer-text">Powered by </span>
        <img alt="Wendor" height="20px" src="/full_logo.png" />
      </div>
    );
  }
}
