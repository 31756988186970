/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { Col, Row } from 'reactstrap';
import { CASH_RETURNER_STAGE, CURRENCY_RETURNER_STAGE } from '../actions/constants';
import { ThreeBubbleLoader } from './Loader';

const OrderCompleteUI = ({
  vendedItems,
  allowOrderBeforeCollection,
  getFeedback,
  feedbackSent,
  totalItemsFailed,
  maxItems,
  paymentMethodUsed,
  giveFeedback,
  isCoinReturner,
  isCurrencyReturner,
  cashReturnerStage,
  currencyReturnerStage,
  coinResponseDetails,
}) => {
  let refundWallet = 'wallet';
  if (paymentMethodUsed === 'paytm') refundWallet = 'PayTM wallet';
  else if (paymentMethodUsed === 'upi') refundWallet = 'UPI app';
  else if (paymentMethodUsed === 'swift' || paymentMethodUsed === 'link' || paymentMethodUsed === 'cash')
    refundWallet = 'inbox for SMS alert of Wendor Swift balance.';

  return (
    <div>
      {isCoinReturner && cashReturnerStage === CASH_RETURNER_STAGE.INIT && (
        <div>
          <ThreeBubbleLoader />
          <p>Initializing Cash Returner</p>
        </div>
      )}
      {isCurrencyReturner && currencyReturnerStage === CURRENCY_RETURNER_STAGE.INIT && (
        <div>
          <ThreeBubbleLoader />
          <p>Initializing Cash Returner</p>
        </div>
      )}
      {isCoinReturner && cashReturnerStage === CASH_RETURNER_STAGE.DISPENSING && (
        <div
          style={{
            height: '40vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <h3>Please collect the change...</h3>
        </div>
      )}
      {isCurrencyReturner && currencyReturnerStage === CURRENCY_RETURNER_STAGE.DISPENSING && (
        <div
          style={{
            height: '40vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <h3>Please collect the change...</h3>
        </div>
      )}
      {isCurrencyReturner && currencyReturnerStage === CURRENCY_RETURNER_STAGE.DISPENSED && (
        <div
          style={{
            height: '40vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Row>
            <Col xs={12}>
              <h3>Please collect</h3>
            </Col>

            {/* {coinResponseDetails &&
              coinResponseDetails.map(({ coinCount, coinValue }) => (
                <Col xs={12}>
                  <h3>{`${coinCount} coins of ${coinValue}`} &#8377;</h3>
                </Col>
              ))} */}
          </Row>
        </div>
      )}

      {isCoinReturner && cashReturnerStage === CASH_RETURNER_STAGE.DISPENSED && (
        <div
          style={{
            height: '40vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Row>
            <Col xs={12}>
              <h3>Please collect</h3>
            </Col>

            {coinResponseDetails &&
              coinResponseDetails.map(({ coinCount, coinValue }) => (
                <Col xs={12}>
                  <h3>{`${coinCount} coins of ${coinValue}`} &#8377;</h3>
                </Col>
              ))}
          </Row>
        </div>
      )}

      {isCoinReturner && cashReturnerStage === CASH_RETURNER_STAGE.BROKEN && (
        <div>
          <h2>Oopsss.....</h2>
          <p>Got error connecting coin changer</p>
        </div>
      )}
      {!isCoinReturner && !isCurrencyReturner && vendedItems.length !== 0 && vendedItems.length !== totalItemsFailed && (
        <Row>
          <Col xs={12}>
            {!allowOrderBeforeCollection && (
              <Row>
                <h4 className="pick-up-button">
                  There is an unpicked product in the delivery box, please collect it before ordering the next item!
                </h4>
              </Row>
            )}
            {getFeedback && !feedbackSent && (
              <>
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <img
                      className="feedback-icon"
                      alt="Bad"
                      src="/assets/Dislike.svg"
                      onClick={() => giveFeedback(0)}
                      role="button"
                    />
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <img
                      className="feedback-icon"
                      alt="Good"
                      src="/assets/like.svg"
                      onClick={() => giveFeedback(5)}
                      role="button"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <h5>{feedbackSent ? 'Thank you for your valuable feedback.' : 'Please rate your experience.'}</h5>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      )}
      {!isCoinReturner &&
        paymentMethodUsed !== 'cash' &&
        !isCurrencyReturner &&
        (totalItemsFailed !== 0 || vendedItems.length === 0) && (
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="big_ion-icon">
                <p>
                  {maxItems === 1 && <h2>We have triggered the refund. </h2>}
                  Please check your <strong>{refundWallet}</strong> in next 3 minutes.
                </p>
                {maxItems > 1 && (
                  <h2>
                    We have triggered the refund for {totalItemsFailed !== 0 ? totalItemsFailed : ''} failed items.
                  </h2>
                )}
              </div>
            </Col>
          </Row>
        )}
    </div>
  );
};

export default OrderCompleteUI;
